<template>
  <div id="tenant" v-loading="loading">
    <div class="cm-content">
      <h-tenant :params="params" @change="handleSearch"></h-tenant>
      <br />
      <el-table :data="data">
        <el-table-column label="租户ID" prop="tenant_id">
          <template slot-scope="scope">
            <el-link type="primary" @click="handleGoDetail(scope.row.pk)">{{
              scope.row.tenant_id
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="租户名称" prop="name"></el-table-column>
        <el-table-column label="户主昵称" prop="user.nickname">
        </el-table-column>
        <el-table-column label="户主手机" prop="user.cellphone">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatTenantState }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.pk)">
              编辑
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleSetApplication(scope.row.pk)"
              >设置应用</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-tenant ref="dialog" @change="handleCurrentChange"></d-tenant>
      <d-tenant-application ref="dialogApplication"></d-tenant-application>
    </div>
  </div>
</template>

<script>
import HTenant from "@/components/headers/HTenant";
import DTenant from "@/components/dialogs/DTenant";
import DTenantApplication from "@/components/dialogs/DTenantApplication";

import tenant from "@/api/tenant";

export default {
  name: "tenant",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HTenant,
    DTenant,
    DTenantApplication,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
      if (route.query.keyword) {
        this.params["keyword"] = route.query.keyword;
      }
    },
    handleList(params) {
      tenant.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleEdit(pk) {
      this.$refs.dialog.handleEdit(pk);
    },
    handleChangeState(pk, data) {
      this.$confirm("是否处理？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          user
            .changeState(pk, data)
            .then(() => {
              this.handleList(this.params);
              this.$message({
                type: "success",
                message: "操作成功！",
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleGoDetail(pk) {
      this.$router.push({
        name: "tenant-detail",
        params: { pk: pk },
      });
    },
    handleSetApplication(val) {
      this.$refs.dialogApplication.handleAdd(val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "tenant",
          query: params,
        })
        .catch(() => {});
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.params = val;
      this.params.page = 1;
      this.$router
        .push({
          name: "tenant",
          query: val,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "tenant") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus" scope>
#tenant {
  display: flex;
  height: 100%;

  .avatar {
    width: 40px;
    height: 40px;
  }
}
</style>
