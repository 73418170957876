<template>
  <div id="h-tenant" class="h-search">
    <el-input
      class="mb10 fl wid-15 mr10"
      size="small"
      clearable
      v-model="keyword"
      placeholder="租户名称模糊查询"
      @change="handleKeywordChange"
    ></el-input>
    <el-select
      class="mb10 fl wid-6 mr10"
      size="small"
      v-model="state"
      placeholder="选择状态"
      clearable
      @change="handleStateChange"
    >
      <el-option
        v-for="(item, key) in optionsState"
        :label="item.label"
        :value="item.value"
        :key="key"
      ></el-option>
    </el-select>
    <el-button
      class="mb10 fl wid-5"
      size="small"
      type="primary"
      @click="handleSearch"
    >
      查询
    </el-button>
  </div>
</template>

<script>
export default {
  name: "h-tenant",
  data() {
    return {
      data: {},
      keyword: "",
      state: "",
      optionsState: [
        {
          label: "已拒绝",
          value: "-1",
        },
        {
          label: "已启用",
          value: "1",
        },
      ],
    };
  },
  props: {
    params: {
      type: Object,
      default: function () {
        return {
          keyword: "",
          state: "",
        };
      },
    },
  },
  created() {
    if (this.params.keyword) {
      this.keyword = this.params.keyword;
    }
    if (this.params.state) {
      this.state = this.params.state;
    }
  },
  methods: {
    handleKeywordChange(val) {
      if (val) {
        this.data["keyword"] = val;
      } else {
        delete this.data.keyword;
      }
    },
    handleStateChange(val) {
      if (val || val === "0") {
        this.data.state = val;
      } else {
        delete this.data.state;
      }
    },
    handleSearch() {
      this.$emit("change", this.data);
    },
  },
};
</script>
